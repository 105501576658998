import React from "react"
import PropTypes from "prop-types"
import { Button, Card, Modal } from "antd"

import "./CardTeam.less"

class CardTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible } = this.state

    return (
      <Card
        bordered={false}
        className="card-team"
        cover={<img alt={this.props.nama} src={this.props.image} />}
      >
        <div className="card-team-meta">
          <p style={{ marginBottom: 0 }} className="card-team-status">
            {this.props.status}
          </p>
          <h3 className="card-team-title">{this.props.nama}</h3>
          <p>{this.props.jabatan}</p>
          {this.props.deskripsi && (
            <>
              <Button type="link" onClick={this.showModal}>
                {this.props.btnText}
              </Button>
              <Modal
                className="modal-team"
                visible={visible}
                onCancel={this.handleCancel}
                footer={false}
              >
                <img
                  alt={this.props.nama}
                  src={this.props.image}
                  width="100%"
                />
                <h3 className="card-team-title" style={{ marginTop: 24 }}>
                  {this.props.nama}
                </h3>
                <p>{this.props.jabatan}</p>
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.deskripsi }}
                ></div>
              </Modal>
            </>
          )}
        </div>
      </Card>
    )
  }
}

CardTeam.propTypes = {
  image: PropTypes.string,
  nama: PropTypes.string,
  status: PropTypes.string,
  deskripsi: PropTypes.string,
  jabatan: PropTypes.string,
  btnText: PropTypes.string,
}

export default CardTeam
